<template>
	<div>
		<div class="flex center pd40 ">
			<div style="width: 378px;">
				<div class="flex space alcenter">
					<div class="cl-main ft20">会员余额</div>
					<div class="cl-price ft20">1000元</div>
				</div>
				<div class="flex space alcenter mt10">
					<div class="cl-main ft20">应收金额</div>
					<div class="cl-price ft16">{{order.need_pay}}元</div>
				</div>
				<div class="flex  center mt40">
					<div class="big-button-pay" @click="payAct()">支付</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			order:{
				type:Object,
				default:function(){
					return new Object
				}
			}
		},
		data(){
			return{
				
			}
		},
		methods:{
			
			
			payAct(){
				
				this.$message.success('支付成功',1,()=>{
					this.$router.push('/order');
				})
			}
		}
	}
</script>

<style>
</style>
