<template>
	<div class="bg-w">
		<div class="pd20" style="height: calc(100vh - 60px);">
			<div class="cash-pay-header">
				<div class="flex alcenter space">
					<div class="ft18 cl-main" >
						<i class="iconfont iconmall_cate ft16"/>
						订单结算
					</div>
					
					<div class="ft18 cl-main" @click="$router.go(-1)">
						收起
						<i class="iconfont iconarrowright_black ft16"/>
					</div>
				</div>
			</div>
			
			<div class="cash-pay-content">
				<div class="flex" style="height: 100%;">
					<div class="cash-pay-list pb20">
						<div class="pd20">
							<div class="mt20 text-center ft20 cl-main">
								订单(未支付)
							</div>
							
							<div class="mt10">
								<div class="flex alcenter">
									<i class="iconfont iconorder_operation_coach cl-info"/>
									会员：{{order.member ? order.member.nick_name+'('+order.member.mobile+')' :'无'}}
								</div>
							</div>
						</div>
						
						<div class="" style="height: 580px;">
							<a-table :columns="columns" :data-source="order.items" 
							rowKey="order_item_id"
							:pagination="false"  
							:scroll="{y: 500}"
							>
							
								<div slot="goods_name" slot-scope="goods_name,record">
									<a-tooltip>
									    <template slot="title">
									     {{goods_name}}
									    </template>
									    <div class="text-over4">{{goods_name}}</div>
									  </a-tooltip>
								</div>
							    <div slot="sku" slot-scope="record">
									{{record.goods_sku_id>0 ? record.goods_sku_name : '-'}}
								</div>
								
								<div slot="goods_price" slot-scope="goods_price,record">
									￥{{goods_price}}
								</div>
							 </a-table>
						</div>
						
						<div class="pd20" >
							
							<div class="flex alcenter space mt10 ">
								<div class="cl-info">需付</div>
								<div class="cl-price">{{order.need_pay}}</div>
							</div>
						</div>
					</div>
					<div class="cash-pay-type">
						<div class="cash-pay-type-content">
							<div class="cash-pay-type-content-header">
								<div class="flex" style="height: 100%;">
									<div class="cash-pay-type-content-header-item alcenter flex center" 
									v-for="(item,index) in pay_type" :key="index"
									 :style="{width : getWidth +'%'}"
									 :class="{active :selectCurrent== item.key,blNone :index == 0 ,brNone:index==pay_type.length-1}"
									 @click="chooseIt(index)"
									 ><i class="iconfont ft22 " :style="{color:item.color}" :class="item.icon"/>{{item.name}}</div>
								</div>
							</div>
							
							<div class="mt10">
								<div v-if="selectCurrent=='cash'">
									<cash-pay :order="order"></cash-pay>
								</div>
								<div v-if="selectCurrent=='wechat'">
									<wechat-pay :order="order"></wechat-pay>
								</div>
								<div v-if="selectCurrent=='money'">
									<money-pay :order="order"></money-pay>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import cashPay from './components/pay/cash.vue';
	import wechatPay from './components/pay/wechat.vue';
	import moneyPay from './components/pay/money.vue';
	export default{
		components:{
			cashPay,
			wechatPay,
			moneyPay
		},
		data(){
			return {
				pay_type:[
					{name:'现金结算',icon:'iconclient_tab04',color:'#01A9EE',key:'cash'},
					// {name:'扫码枪支付',icon:'',key:'scran'},
					{name:'微信支付',icon:'iconwepay',color:'#09BB0D',key:'wechat'},
				],
				selectCurrent:'cash',
				columns: [
					{title: '名称',dataIndex: 'goods_name',align: 'center',width:100,scopedSlots: {customRender: 'goods_name'}},
					{title: '规格',key: 'sku',align: 'center',scopedSlots: {customRender: 'sku'}},
					{title: '价格',dataIndex: 'goods_price',align: 'center',scopedSlots: {customRender: 'goods_price'}},
					{title: '数量',dataIndex: 'goods_num',align: 'center'},
				],
				order:{
					order_id:1,
					member:null,
					items:[],
					need_pay:'0',
				},
			}
		},
		created(){
			this.loaddata();
			if(this.order.member){
				this.pay_type.unshift({
					name:'余额结算',icon:'iconmoney_setting',color:'#FF8400',key:'money'
				})
				
				this.selectCurrent='money';
			}
		},
		computed:{
			
			getWidth(){
				let len = this.pay_type.length;
				return 100/len;
			},
		},
		methods:{
			
			loaddata(){
				this.order={
					order_id:1,
					member:null,
					items:[
						{order_item_id:1,goods_name:'测试商品',goods_sku_id:1,goods_sku_name:'测试商品规格',goods_original_price:176.00,goods_price:166.00,goods_num:2},
						{order_item_id:2,goods_name:'测试商品1',goods_sku_id:0,goods_original_price:186.00,goods_price:176.00,goods_num:1}
					],
					need_pay:'563.00',
				}
			},
			chooseIt(index){
				this.selectCurrent=this.pay_type[index].key;
			}
		}
	}
</script>

<style>
	.cash-pay-header{
		background: #F5F5F5;
		width: 100%;
		height: 60px;
		line-height: 60px;
		padding: 0px 20px;
	}
	
	.cash-pay-content{
		border: 1px solid #F5F5F5;
		border-top: none;
	}
	
	.cash-pay-list{
		border-right: 4px solid #F5F5F5;
		/* padding: 20px; */
		width: 35%;
		height: 100%;
	}
	
	.cash-pay-type{
		width: 69%;
		height: 100%;
		padding: 20px;
		border-radius: 4px;
	}
	
	.cash-pay-type-content{
		border: 1px solid #DEE1E7;
		height: 100%;
	}
	
	.cash-pay-type-content-header{
		background: #F5F7FA;
		height: 60px;
		line-height: 58px;
		/* border: 1px solid #E9EBF1; */
		overflow: hidden;
	}
	
	.cash-pay-type-content-header-item{
		height: calc(100% - 2px);
		border-bottom: 1px solid #DEE1E7;
		text-align: center;
	}
	
	.cash-pay-type-content-header-item.active{
		background : #FFFFFF;
		margin-top: 4px;
		border-left: 1px solid #DEE1E7;
		border-right: 1px solid #DEE1E7;
		border-bottom:none;
	}
	
	.blNone{
		border-left: none !important;
	}
	
	.brNone{
		border-right: none !important;
	}
</style>
