<template>
	<div>
		<div class="flex center pd40 ">
			<div style="width: 378px;">
				<div class="flex space alcenter">
					<div class="cl-main ft20">应收金额</div>
					<div class="cl-price ft16">{{order.need_pay}}元</div>
				</div>
			
				<div class="mt40" >
					<a-input-search size="large" placeholder="点击文本框,用扫码枪扫客户的支付码" enter-button @search="onSearch" />
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			order:{
				type:Object,
				default:function(){
					return new Object
				}
			}
		},
		data(){
			return{
				
			}
		},
		
		methods:{
			onSearch(){
				
			},
		}
	}
</script>

<style>
	.wechat-img{
		width: 400px;
		height: 400px;
	}
</style>
